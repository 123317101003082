@import "variables";
@import "mixins";

.body {
  @include pc {
    width: 550px;
    margin: 0 auto;
  }
  @include sp {
    width: 100%;
  }

  p {
    font-size: 15px;
  }

  h2 {
    font-weight: 400;
    @include pc {
      margin: 60px 0 30px;
      font-size: 20px;
    }
    @include sp {
      margin: 40px 0 20px;
      font-size: 18px;
    }
  }
}
